import {Box} from '@mui/material'
import React from 'react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'

export function ProductPreOrderChip() {
    return <Box
      className='preorder'
      sx={{
        typography: 'caption',
        bgcolor: 'text.primary',
        fontWeight: 'fontWeightBold',
        border: 1,
        borderColor: 'divider',
        padding: '0px 6px',
        color: 'background.default',
        display: 'inline-block',
      }}
    >
      <Trans id='Pre-order' />
    </Box>
}
