import { AddProductsToCartFab, ProductListItemRenderer } from '@graphcommerce/magento-product'
import { ProductListItemBundle } from '@graphcommerce/magento-product-bundle'
import { ProductListItemDownloadable } from '@graphcommerce/magento-product-downloadable'
import { ProductListItemGrouped } from '@graphcommerce/magento-product-grouped'
import { ProductListItemVirtual } from '@graphcommerce/magento-product-virtual'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { ProductListItemSimple } from './Renderer/ProductListItemSimple'
import { ProductListItemConfigurable } from './Renderer/ProductListItemConfigurable'
import {ProductSecondHandChip} from '../ProductSecondHandChip'
import {ProductPreOrderChip} from '../ProductPreOrderChip'

export const productListRenderer: ProductListItemRenderer = {
  SimpleProduct: (props) => {
    const { sku, short_description, name, display_name, second_hand, pre_order } = props
    const topLeft = (() => {
      if (second_hand === 1)
        return <ProductSecondHandChip />
      if (pre_order === 1 )
        return <ProductPreOrderChip />
      return null
    })()

    return (
      <ProductListItemSimple
        {...props}
        name={display_name ?? name}
        subTitle={short_description?.html}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
        topLeft={topLeft}
      />
    )
  },
  ConfigurableProduct: (props) => {
    const { short_description, name, display_name, second_hand, pre_order } = props
    const topLeft = (() => {
      if (second_hand === 1)
        return <ProductSecondHandChip />
      if (pre_order === 1 )
        return <ProductPreOrderChip />
      return null
    })()
    return <ProductListItemConfigurable
      {...props}
      name={display_name ?? name}
      subTitle={short_description?.html}
      aspectRatio={[1, 1]}
      swatchLocations={{
        topLeft: [],
        topRight: [],
        bottomLeft: [],
        bottomRight: [],
      }}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
      topLeft={topLeft}
    />
  },
  BundleProduct: (props) => (
    <ProductListItemBundle
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  VirtualProduct: (props) => {
    const { sku } = props
    return (
      <ProductListItemVirtual
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
      />
    )
  },
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  GroupedProduct: (props) => (
    <ProductListItemGrouped
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore GiftCardProduct is only available in Commerce
  // GiftCardProduct: (props) => (
  //   <ProductListItem {...props} aspectRatio={[1, 1]} />
  // ),
}
